import "../components/base.css";
import Layout from "../components/layout";
import React from "react";


export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <br></br>
          <h1>&gt; MINE | TRADE</h1>
        </div>
        <br></br>
        <div>
          <h2>GET STARTED MINING /</h2>
            <h3><p>Read Benjamin Danneberg's <a rel="noreferrer" target="_blank" href="https://space4games.com/en/games-en/star-citizen-mining/">Comprehensive Mining Guide</a></p></h3>
          <br></br>
        </div>
        <div>
          <h2>GET STARTED TRADING /</h2>
            <h3><p>Spend some time in the <a rel="noreferrer" target="_blank" href="https://www.gallog.co/">Galactic Logistics Tool Site</a></p></h3>
          <br></br>
        </div>
      </Layout>
    );
  }
}